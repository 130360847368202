import { ElementRef, Injectable, Renderer2, inject } from '@angular/core';
import { AsyncSubject, ReplaySubject, lastValueFrom } from 'rxjs';

import { PanoramaDataDTO } from '@yuno/api/interface';

import {
	FovType,
	KrpanoEmbedOptions,
	KrpanoInstance,
	KrpanoSetup,
	LimitViewType,
	ViewTypes
} from '../types';
import { KrpanoEventsService } from './krpano-events.service';
import { KrpanoLoaderService } from './krpano-loader.service';
import { PanoramaOffset } from './resize.service';
import { WindowRefService } from './window-ref.service';

export const KRPANO_SCRIPT = 'https://cdn.projectatlas.app/content/libraries/krpano/1.20.11';

declare let embedpano: (options: KrpanoEmbedOptions) => unknown;
declare let removepano: (str: string) => unknown;

@Injectable({
	providedIn: 'root'
})
export class PanoramaService {
	private winRef = inject(WindowRefService);
	private events = inject(KrpanoEventsService);
	private loader = inject(KrpanoLoaderService);

	el?: ElementRef;

	// Stores the reference to the KRPANO instance used by the eventHooks
	private _panoramaCreated = new AsyncSubject<void>();
	panoramaCreated$ = this._panoramaCreated.asObservable();

	private krpano: KrpanoInstance | undefined;
	krpanoInstance: KrpanoInstance;

	private _pano = new ReplaySubject<PanoramaDataDTO | undefined>();
	pano$ = this._pano.asObservable();

	offset: PanoramaOffset;
	color = '#000000';

	keepView = false;

	_panorama?: PanoramaDataDTO;
	set panorama(pano: PanoramaDataDTO | undefined) {
		if (!pano) {
			this._panorama = undefined;
			this._pano.next(this._panorama);
			return;
		}
		this._panorama = pano;
		this.height = pano.location?.height || 0;
		this.coordinates = pano.location?.coordinates || [0, 0];

		this._pano.next(this._panorama);
	}

	get panorama(): PanoramaDataDTO | undefined {
		return this._panorama;
	}

	coordinates: [number, number];
	height: number;
	range = 2000;
	epsg = 'EPSG:28992';

	vrModeEnabled = false;
	vrModeActivated = false;
	threejsActivated = false;

	// Create a reference to the KRpanoInstance
	private store(krpano: KrpanoInstance): void {
		this.krpano = krpano;
		this.krpanoInstance = krpano.get('global') as KrpanoInstance;

		this._panoramaCreated.next();
		this._panoramaCreated.complete();
	}

	// Loads the KRPano Script
	// when loaded start the initialization process
	async loadKrpanoScript(renderer: Renderer2): Promise<void> {
		return new Promise<void>(resolve => {
			// check if the kprano javascript is already loaded
			if (this.winRef.nativeWindow['krpanoJS']) {
				return resolve();
			}

			// Else load Krpano Javascript
			const krpanoScript = this.loader.loadJsScript(renderer, `${KRPANO_SCRIPT}/krpano.js`);

			krpanoScript.onload = () => {
				resolve();
			};

			krpanoScript.onerror = () => {
				throw new Error('Could not load krpano.js!');
			};
		});
	}

	// Setup KRPANO
	// first we need to make sure no other
	// instances of KRPano are active
	// then load krpano with the Embedpano method
	setup(options: KrpanoSetup): void {
		// reset KRpano, global krpano interface (will be set in the onready callback)
		this.destroy();

		// Load KRpano
		embedpano({
			id: 'krpano-' + Date.now(),
			target: options.panoOptions.element,
			xml: options.panoOptions.xml,
			html5: 'only',
			consolelog: !!options.panoOptions.enableLogs,
			initvars: { CDN: `${KRPANO_SCRIPT}` },
			onerror: err => console.error(err),
			onready: krpano => this.onReadyCallback(options, krpano)
		});
	}

	// When KRPano is ready
	// create all references needed to function
	// and register all hooks to events
	async onReadyCallback(options: KrpanoSetup, krpano: KrpanoInstance): Promise<void> {
		await this.store(krpano);

		// register all events
		this.events.hookEvents(options.panoEvents, this.krpanoInstance as KrpanoInstance);
	}

	// Zoom on Panorama
	zoomOnPanorama(zoom: number): void {
		this.krpano?.call('set(fov_moveforce, ' + zoom + ')');
	}

	updatePanorama(xml: string, newPano?: boolean): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.krpanoInstance.actions.loadpano(
				xml,
				null,
				this.keepView || !newPano ? 'MERGE|KEEPVIEW' : 'MERGE',
				'SLIDEBLEND(1.0, 180, 0.2, linear)'
			);

			// Controls
			this.krpanoInstance.actions.includexml(`${KRPANO_SCRIPT}/yuno/controls.xml`);

			// VR
			this.krpanoInstance.actions.includexml(`${KRPANO_SCRIPT}/yuno/vtourskin.xml`);

			// THREEJS
			this.krpanoInstance.actions.includexml(`${KRPANO_SCRIPT}/yuno/threejs.xml`);

			setTimeout(() => {
				this.krpanoInstance.call('display.requestresize()');
				resolve(true);
			}, 100);
		});
	}

	destroy(): void {
		if (!this.krpano) {
			return;
		}

		removepano(this.krpano.id);
		this.krpano = undefined;
	}

	async setViewSettings(viewSetting: ViewTypes, value: unknown): Promise<void> {
		await lastValueFrom(this.panoramaCreated$);

		switch (viewSetting) {
			case 'fovType':
				this.krpanoInstance.view.fovType = value as FovType;
				break;
			case 'stereographic':
			case 'architecturalonlymiddle':
				this.krpanoInstance.view[viewSetting] = value as boolean;
				break;
			case 'limitview':
				this.krpanoInstance.view.limitview = value as LimitViewType;
				break;
			default:
				this.krpanoInstance.view[viewSetting] = value as number;
				break;
		}
	}
}
