import { EventEmitter } from '@angular/core';
import { Object3D } from 'three';

export type KrpanoEmbedOptions = {
	id: string; // custom ID to destroy the WebGL context later
	xml: null | string; // string containing XML values or URL to XML file
	target: HTMLElement; // embed the krpano viewer into this element
	html5: 'only'; // forces HTML5 mode
	consolelog: boolean; // trace krpano messages also to the browser console
	vars?: { [key: string]: string }; // The variables will be set AFTER the xml file has been be loaded and parsed.
	initvars?: { [key: string]: string }; // This is basically the same as the vars setting, but these variables will be set BEFORE the xml file wil be loaded and parsed.
	onerror: (err: string) => void; // when krpano fails loading and errors
	onready: (krpano: KrpanoInstance) => void; // when krpano is loaded
};

export type KrpanoSetup = {
	panoOptions: {
		xml: string;
		element: HTMLElement;
		enableLogs?: boolean;
	};
	panoEvents: KrpanoEvents;
};

type LoadFlags =
	| 'MERGE'
	| 'PRELOAD'
	| 'KEEPIMAGE'
	| 'KEEPVIEW'
	| 'KEEPMOVING'
	| 'KEEPLIMITS'
	| 'KEEPLOOKAT'
	| 'KEEP3D'
	| 'KEEPSCENES'
	| 'KEEPPLUGINS'
	| 'KEEPHOTSPOTS'
	| 'NOPREVIEW'
	| 'REMOVESCENES'
	| 'IGNOREKEEP'
	| 'IMAGEONLY';

type LoadFlagsDual = `${LoadFlags}|${LoadFlags}`;
type LoadFlagsTriple = `${LoadFlags}|${LoadFlags}|${LoadFlags}`;

export type LoadFlagsCombined = LoadFlags | LoadFlagsDual | LoadFlagsTriple;

export class KrpanoInstance extends HTMLElement {
	// get: (name: 'global') => KrpanoInstance;
	set: (str: string, val: unknown) => unknown;
	get: (str: string) => unknown;
	call: (str: string) => void;
	events: {
		onenterfullscreen: () => void;
		onexitfullscreen: () => void;
		onxmlcomplete: () => void;
		onpreviewcomplete: () => void;
		onloadcomplete: () => void;
		onblendcomplete: () => void;
		onnewpano: () => void;
		onremovepano: () => void;
		onnewscene: () => void;
		onxmlerror: () => void;
		onloaderror: () => void;
		onkeydown: () => void;
		onkeyup: () => void;
		onclick: () => void;
		onsingleclick: () => void;
		ondoubleclick: () => void;
		onmousedown: () => void;
		onmouseup: () => void;
		onmousewheel: () => void;
		oncontextmenu: () => void;
		onidle: () => void;
		onviewchange: () => void;
		onviewchanged: () => void;
		onresize: () => void;
		onframebufferresize: () => void;
		onautorotatestart: () => void;
		onautorotatestop: () => void;
		onautorotateoneround: () => void;
		onautorotatechange: () => void;
		oniphonefullscreen: () => void;
		gyro_onavailable: () => void;
		gyro_onunavailable: () => void;
		gyro_onenable: () => void;
		gyro_ondisable: () => void;
		webvr_onavailable: () => void;
		webvr_onunavailable: () => void;
		webvr_onunknowndevice: () => void;
		webvr_onentervr: () => void;
		webvr_onexitvr: () => void;
	};
	view: {
		hlookat: number;
		vlookat: number;
		camroll: number;
		fovType: FovType;
		fov: number;
		hfov: number;
		fovMin: number;
		fovMax: number;
		maxpixelzoom: number;
		mfovratio: number;
		distortion: number;
		distortionfovlink: number;
		stereographic: boolean;
		pannini: number;
		architectural: number;
		architecturalonlymiddle: boolean;
		limitview: LimitViewType;
		hlookatmin: number;
		hlookatmax: number;
		vlookatmin: number;
		vlookatmax: number;
		rx: number;
		ry: number;
		tx: number;
		ty: number;
		tz: number;
		ox: number;
		oy: number;
		oz: number;
	};

	/**
	 * KRPANO Documentation
	 * https://krpano.com/docu/actions
	 */
	actions: {
		// https://krpano.com/docu/actions/#loadpano
		loadpano: (
			xmlpath: string,
			vars?: string | null,
			flags?: LoadFlagsCombined | null,
			blend?: string | null,
			loaddone?: () => unknown
		) => void;
		loadscene: (
			scenename: string,
			vars?: string | null,
			flags?: LoadFlagsCombined | null,
			blend?: string | null,
			loaddone?: () => unknown
		) => void;
		loadpanoscene: (
			xmlpath: string,
			scenename: string,
			vars?: string | null,
			flags?: LoadFlagsCombined | null,
			blend?: string | null,
			loaddone?: () => unknown
		) => void;
		loadpanoimage: (
			flags?: LoadFlagsCombined | null,
			blend?: string | null,
			loaddone?: () => unknown
		) => void;
		loadxml: (
			xml: string,
			vars?: string | null,
			flags?: LoadFlagsCombined | null,
			blend?: string | null,
			loaddone?: () => unknown
		) => void;
		// end loadpano
		// https://krpano.com/docu/actions/#lookto
		lookto: (
			toH: number,
			toV: number,
			fov?: number,
			motiontype?: string,
			shortestway?: boolean,
			nonblocking?: boolean,
			donecall?: () => unknown
		) => void;
		looktohotspot: (
			hotspotname?: string,
			fov?: number,
			motiontype?: string,
			shortestway?: boolean
		) => void;
		moveto: (toH: number, toV: number, motiontype?: string) => void;
		zoomto: (fov: number, motiontype?: string) => void;
		// end lookto
		// lookat
		// https://krpano.com/docu/actions/#lookat
		lookat: (
			h: number,
			v: number,
			fov?: number,
			distortion?: string,
			architectural?: string,
			pannini?: string
		) => void;
		// end lookat
		// includeXML
		// https://krpano.com/docu/actions/#includexml
		includexml: (url: string, loaddone?: () => unknown) => void;
		includexmlstring: (string: string, loaddone?: () => unknown) => void;
		// end includeXML
		// others
		stoplookto: () => void;
		stopmovements: () => void;
		wait: (parameter: number | 'LOAD' | 'BLEND') => void;
		updatescreen: () => void;
		invalidatescreen: () => void;
		// https://krpano.com/docu/actions/#addlayer
		addlayer: (name: string, varname?: unknown) => void;
		addplugin: (name: string, varname?: unknown) => void;
		addhotspot: (name: string, varname?: unknown) => void;
		removelayer: (name: string, removechildren?: boolean) => void;
		removeplugin: (name: string, removechildren?: boolean) => void;
		removehotspot: (name: string) => void;
	};
}

export type KrpanoDevice = {
	webgl: never;
	pixelratio: number;
	browser: {
		events: {
			mouse: never;
			touch: never;
			touchstart: string;
			touchend: string;
		};
	};
};

export type KrpanoInterface = {
	version: string;
	debugmode: boolean;
	device: KrpanoDevice;
	stagescale: number;
	webGL: {
		context: WebGLRenderingContext;
		restoreProgram: () => void;
		canvas: never;
	};
	control: {
		getMousePos: (input: unknown) => { x: number; y: number };
		layer: {
			addEventListener: (
				arg0: string,
				arg1: {
					(e: never): void;
					(e: never): void;
					(e: never): void;
				},
				arg2: boolean
			) => void;
		};
	};
	area: {
		pixelwidth: number;
		pixelheight: number;
	};
	view: {
		fisheye: number;
		v: number;
		h: number;
		r: number;
		z: number;
		yf: number;
		continuousupdates: boolean;
		getState: (input: unknown) => KrpanoInterface['view'];
	};
	set: (arg0: string, arg1: boolean | (() => void)) => void;
	get: (arg0: string) => number;
	trace: (arg0: number, arg1: string) => void;
};

export type FovType = 'VFOV' | 'HFOV' | 'DFOV' | 'MFOV' | 'SFOV';
export type LimitViewType = 'off' | 'auto' | 'lookat' | 'range' | 'fullrange' | 'offrange';

export const ViewTypesArr = [
	'hlookat',
	'vlookat',
	'camroll',
	'fovType',
	'fov',
	'fovMin',
	'fovMax',
	'maxpixelzoom',
	'mfovratio',
	'distortion',
	'distortionfovlink',
	'stereographic',
	'pannini',
	'architectural',
	'architecturalonlymiddle',
	'limitview',
	'hlookatmin',
	'hlookatmax',
	'vlookatmin',
	'vlookatmax',
	'rx',
	'ry',
	'tx',
	'ty',
	'tz',
	'ox',
	'oy',
	'oz'
];

export const ViewTypesArray = [
	'hlookat',
	'vlookat',
	'camroll',
	'fovType',
	'fov',
	'fovMin',
	'fovMax',
	'maxpixelzoom',
	'mfovratio',
	'distortion',
	'distortionfovlink',
	'stereographic',
	'pannini',
	'architectural',
	'architecturalonlymiddle',
	'limitview',
	'hlookatmin',
	'hlookatmax',
	'vlookatmin',
	'vlookatmax',
	'rx',
	'ry',
	'tx',
	'ty',
	'tz',
	'ox',
	'oy',
	'oz'
] as const;

export const EventTypesArray = [
	'onenterfullscreen',
	'onexitfullscreen',
	'onxmlcomplete',
	'onpreviewcomplete',
	'onloadcomplete',
	'onblendcomplete',
	'onnewpano',
	'onremovepano',
	'onnewscene',
	'onxmlerror',
	'onloaderror',
	'onkeydown',
	'onkeyup',
	'onclick',
	'onsingleclick',
	'ondoubleclick',
	'onmousedown',
	'onmouseup',
	'onmousewheel',
	'oncontextmenu',
	'onidle',
	'onviewchange',
	'onviewchanged',
	'onresize',
	'onframebufferresize',
	'onautorotatestart',
	'onautorotatestop',
	'onautorotateoneround',
	'onautorotatechange',
	'oniphonefullscreen',
	'gyro_onavailable',
	'gyro_onunavailable',
	'gyro_onenable',
	'gyro_ondisable',
	'webvr_onavailable',
	'webvr_onunavailable',
	'webvr_onunknowndevice',
	'webvr_onentervr',
	'webvr_onexitvr'
] as const;

export type EventTypes = (typeof EventTypesArray)[number];
export type ViewTypes = (typeof ViewTypesArray)[number];

export interface KrpanoEvents {
	onenterfullscreen: EventEmitter<void>;
	onexitfullscreen: EventEmitter<void>;
	onxmlcomplete: EventEmitter<void>;
	onpreviewcomplete: EventEmitter<void>;
	onloadcomplete: EventEmitter<void>;
	onblendcomplete: EventEmitter<void>;
	onnewpano: EventEmitter<void>;
	onremovepano: EventEmitter<void>;
	onnewscene: EventEmitter<void>;
	onxmlerror: EventEmitter<void>;
	onloaderror: EventEmitter<void>;
	onkeydown: EventEmitter<void>;
	onkeyup: EventEmitter<void>;
	onclick: EventEmitter<void>;
	onsingleclick: EventEmitter<void>;
	ondoubleclick: EventEmitter<void>;
	onmousedown: EventEmitter<void>;
	onmouseup: EventEmitter<void>;
	onmousewheel: EventEmitter<void>;
	oncontextmenu: EventEmitter<void>;
	onidle: EventEmitter<View>;
	onviewchange: EventEmitter<View>;
	onviewchanged: EventEmitter<View>;
	onresize: EventEmitter<void>;
	onframebufferresize: EventEmitter<void>;
	onautorotatestart: EventEmitter<void>;
	onautorotatestop: EventEmitter<void>;
	onautorotateoneround: EventEmitter<void>;
	onautorotatechange: EventEmitter<void>;
	oniphonefullscreen: EventEmitter<void>;
	gyro_onavailable: EventEmitter<void>;
	gyro_onunavailable: EventEmitter<void>;
	gyro_onenable: EventEmitter<void>;
	gyro_ondisable: EventEmitter<void>;
	webvr_onavailable: EventEmitter<void>;
	webvr_onunavailable: EventEmitter<void>;
	webvr_onunknowndevice: EventEmitter<void>;
	webvr_onentervr: EventEmitter<void>;
	webvr_onexitvr: EventEmitter<void>;
}

export interface View {
	vlookat: number;
	hlookat: number;
	fov: number;
}

export interface KrpanoObjectProperties {
	name?: string;
	ath: number;
	atv: number;
	depth: number;
	scale: number;
	rx: number;
	ry: number;
	rz: number;
	rorder?: 'YXZ' | 'XYZ';
	enabled?: boolean;
	capture?: boolean;
	onover?: unknown;
	onout?: unknown;
	ondown?: unknown;
	onup?: unknown;
	onclick?: unknown;
	pressed?: boolean;
	hovering?: boolean;
}

export type MeshKrpano = Object3D & {
	properties?: KrpanoObjectProperties;
};
